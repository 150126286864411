<template>
    <div style="padding-bottom: 50px;">
        <van-notice-bar mode="closeable" v-if="no_service" wrapable :scrollable="false">
            您尚未购买来飞网的任何服务。
        </van-notice-bar>
        <div class="card_mine" @click="$router.push('/vipCard')" v-if="vip.vip_id">
            <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/card_back.png" class="home_swipe_back" alt="">
            <div style="padding-top: 50px;">
                <div style="width: 100%;">
                    <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/explore/cardn.png" class="swipe_back swipe_back_mine" alt="">
                </div>
                <div class="card_mine_detail">
                    <div style="height: 100%;display: flex;flex-direction: column;">
                        <div class="card_detail_top">
                            <span>黑卡会员</span>
                            <span>查看详情&nbsp;></span>
                            <div class="card_headimg" style="position: absolute;">
                                <div>
                                    <img :src="vip.headimgurl" alt=""/>
                                    <span>V</span>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex;width:100%;flex-grow: 1;">
                            <div style="font-size: 12px;flex: 1 0 auto;padding:15px 0 0 10px;">
                                <p>尊享专属特权，包月服务更优惠</p>
                            </div>
                            <div style="flex: 0 0 auto;">
                                <img src="../../assets/img/king.png" style="display: block;width: 60px;float:right;margin:5px 20px 0 0;" alt="">
                            </div>
                        </div>
                        <div class="card_detail_bottom" style="margin-bottom: 5px;">
                            <div>您的黑卡会员有效期至{{vip.end_date}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div>
                <div style="font-size: 14px;font-weight: bold;padding: 0px 10px 0px 16px;line-height:40px;border-radius:10px 10px 0 0;margin-bottom: 0;">
                    <span>补写点评</span>
                    <span style="float: right;">
                        <van-button size="small"
                                    style="border-radius: 15px;padding: 0 15px;margin-right:4px;"
                                    @click="$router.push('/explore/buy')">购买
                        </van-button>
                        <van-button color="#e2ba7f" size="small"
                                    style="border-radius: 15px;padding: 0 15px;margin-right:4px;" @click="exploreApply">发布
                        </van-button>
                    </span>
                </div>
                <div style="background: #fff;border-radius: 0 0 10px 10px;overflow: hidden;border-top: 1px solid #ebedf0;" v-if="explore.length">
                    <van-cell-group>
                        <van-cell v-for="(item,index) in explore" :key="index" :title="item.channel_name">
                            <span style="color: #e2ba7f;font-size: 18px;font-weight: bold;">{{item.remaining_times}}&nbsp;</span>次
                            <template #right-icon>
                                <van-button size="mini" round style="margin: 1px 0 0 5px;padding: 0 10px;" @click="refundShow(item)" :disabled="item.remaining_times === 0 && !item.refunding_number">{{item.refunding_number?'正在退款':'退款'}}</van-button>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>

        <div class="container">
            <div>
                <div style="font-size: 14px;font-weight: bold;padding: 0px 10px 0px 16px;line-height:40px;border-radius:10px 10px 0 0;margin-bottom: 0;">
                    <span>霸王餐</span>
                    <span style="float: right;">
                        <van-button size="small"
                                    style="border-radius: 15px;padding: 0 15px;margin-right:4px;"
                                    @click="$router.push('/lottery/buy')">购买
                        </van-button>
                        <van-button color="#e2ba7f" size="small"
                                    style="border-radius: 15px;padding: 0 15px;margin-right:4px;" @click="lotteryApply">发布
                        </van-button>
                    </span>
                </div>
                <div style="background: #fff;border-radius: 0 0 10px 10px;overflow: hidden;border-top: 1px solid #ebedf0;" v-if="remaining.length">
                    <van-cell-group>
                        <van-cell :title="item.name" v-for="item in remaining">
                            <span style="color: #e2ba7f;font-size: 18px;font-weight: bold;">{{item.remaining_times}}&nbsp;</span>次
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>

        <div class="container" v-if="course.length">
            <div style="overflow:hidden;">
                <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-radius:10px 10px 0 0;margin-bottom: 0;border-bottom: 1px solid #ebedf0;">
                    我的课程
                </div>
                <div>
                    <van-card v-for="item in course"
                              @click="lessonDetail(item)"
                              style="background: #fff;"
                              v-bind:key="item.c_id"
                              :thumb="item.title_img">
                        <div slot="title">
                            <p style="font-weight: bold;font-size: 15px;margin: 0 0 3px;">{{item.title}}</p>
                        </div>
                        <div slot="desc">
                            <span style="color: #666;font-size: 12px;">{{item.subtitle}}</span>
                        </div>
                        <div slot="num">
                            <van-button color="#e2ba7f" size="small" style="border-radius: 15px;padding: 0 15px;">进入学习
                            </van-button>
                        </div>
                    </van-card>
                </div>
            </div>
        </div>

        <div class="container" v-if="order_history.length">
            <div style="overflow: hidden;">
                <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-radius:10px 10px 0 0;margin-bottom: 0;border-bottom: 1px solid #ebedf0;">
                    购买记录
                </div>
                <van-steps direction="vertical" :active="0" style="margin-top: 0;">
                    <van-step v-for="(item,index) in order_history" :key="index">
                        <p style="margin-top: 0;">{{item.detail}}</p>
                        <p style="margin-bottom: 0;">{{item.success_at}}</p>
                    </van-step>
                </van-steps>
            </div>
        </div>
        <shop-tabbar-dialog :active="2" @navigate="navigate"></shop-tabbar-dialog>
        <van-popup v-model="show" position="bottom" round closeable safe-area-inset-bottom close-on-popstate style="padding-top: 44px;">
            <van-cell-group>
                <van-cell v-model="refund.remaining" title="可用数量" />

                <van-cell v-if="refund.number" v-model="refund.number" title="退款总数" />
                <van-cell v-if="refund.number" v-model="refund.refunded_number" title="退款成功数量" />
                <van-cell v-if="refund.number" v-model="refund.refunding_number" title="正在退款数量" />
                <van-cell v-if="refund.number" v-model="refund.return_number" title="退款失败待退回余额" />

                <van-cell title="退款数量"  v-if="!refund.number">
                    <van-stepper v-model="refund.refund_number" theme="round" button-size="22" disable-input  :max="refund.remaining" min="0" />
                </van-cell>

                <div style="padding: 5px;">
                    <van-button size="large" type="danger" round style="height: 40px;" v-if="refund.number" disabled>正在退款</van-button>
                    <van-button size="large" type="danger" round style="height: 40px;" @click="refundSubmit" v-else :disabled="!refund.refund_number">申请退款</van-button>
                </div>
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
    import {Toast, Dialog, NoticeBar} from 'vant';
    import ShopTabbarDialog from "../Common/Dialog/ShopTabbarDialog";

    export default {
        name: "Mine",
        components:{
            ShopTabbarDialog,
        },
        data() {
            return {
                vip: {
                    end_date: '0000年00月00日',
                },
                course: [],
                explore: [],
                order_history: [],
                shops: false,
                goods:false,
                goods_permit:false,
                remaining: [],
                active: 2,
                no_service: false,
                show:false,
                refund:{
                    channel_id:0, // 渠道id
                    refund_number:0, // 退款数量
                    remaining:0, // 可用数量

                    number:0, // 退款总数
                    refunded_number:0, // 已退款数量
                    refunding_number:0, //正在退款数量
                    return_number:0, //退款失败返回余额数量
                },
            }
        },
        mounted() {
            this.loadData();
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        methods: {
            navigate(page){
                this.$router.replace(page);
            },
            // 查询信息
            loadData() {
                this.post('/api/client/mine/mine', {}).then(result => {
                    if (result.code === 0) {
                        this.vip = result.data.vip;
                        this.course = result.data.course;
                        this.explore = result.data.explore;
                        this.order_history = result.data.order_history;
                        this.shops = result.data.shops;
                        this.goods = result.data.goods;
                        this.goods_permit = result.data.goods_permit;
                        this.remaining = result.data.remaining;
                        if (!result.data.vip.vip_id && !result.data.course.length && !result.data.explore.length && !result.data.order_history.length) {
                            this.no_service = true;
                        }
                    } else {
                        Toast.fail(result.msg);
                    }
                })
            },
            lessonDetail(item) {
                this.$router.push({path: '/courseDetail', query: {course_id: item.course_id}});
            },
            exploreApply() {
                if (!this.shops) {
                    Dialog.confirm({
                        title: '提示',
                        message: '您还未绑定店铺信息，是否前往绑定？',
                    }).then(() => {
                        this.$router.push('/shop');
                    });
                } else {
                    // 检查点评补发是否还有余额
                    let explore = this.explore;
                    for(let i = 0; i < explore.length; i++){
                        if(explore[i].remaining_times > 0){
                            this.$router.push({path: '/explore/apply'});
                            return;
                        }
                    }
                    this.$dialog.alert({
                        title: '提示',
                        message: '您暂无可用的探店剩余次数，请先购买',
                    })
                }
            },
            lotteryApply() {
                if (!this.goods) {
                    Dialog.confirm({
                        title: '提示',
                        message: this.shops.length? '您还未设置霸王餐活动策略，是否前往设置？': '您还未设置店铺信息，是否前往绑定？',
                    }).then(() => {
                        this.$router.replace('/shop');
                    });
                } else {
                    if(this.remaining.length > 0){
                        this.$router.push({path: '/lottery/apply'});
                    } else {
                        this.$dialog.alert({
                            title: '提示',
                            message: '您暂无可用的霸王餐剩余次数，请先购买',
                        })
                    }
                }
            },
            refundShow(item){
                this.refund = {
                    channel_id:item.channel_id,
                    remaining:item.remaining_times,
                    refund_number:0,

                    number:item.number,
                    refunded_number:item.refunded_number,
                    refunding_number:item.refunding_number,
                    return_number:item.return_number,
                };
                this.show = true;
            },
            refundSubmit(){
                Dialog.confirm({
                    title: '提示',
                    message: '将退款到您的原支付账户。退款有一定延时，用零钱支付的退款20分钟内到账，银行卡支付的退款3个工作日内到账。',
                }).then(() => {
                    this.post('/api/client/shop/explore/refund', this.refund).then(result => {
                        if (result.code === 0) {
                            this.$dialog.alert({
                                title: '申请退款成功',
                                message: '请查收微信的退款通知,如退款失败请联系在线客服。',
                            })
                            this.show = false;
                            this.loadData();
                        } else {
                            Toast.fail(result.msg);
                        }
                    })
                });
            }
        }
    }
</script>

<style>
    .coursesss .van-collapse-item__content {
        padding: 0;
    }

    .explore_collapse .van-collapse-item__content {
        padding: 0;
    }

    .container > div {
        margin: 10px;
        border: 1px solid #eee;
        border-radius: 10px;
    }

    .shop-cell {
        border-radius: 0 0 10px 10px;
        overflow: hidden;
    }

    .shop-cell .van-cell__title {
        flex: 0 0 60px;
    }

    .shop-cell .van-cell__value {
        flex: 1 1 auto;
    }

    .van-card__thumb{
        height: 70px;
        width: 70px;
    }
    .van-card__content{
        min-height: 70px;
        padding-left: 5px;
    }
</style>